import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import './index.scss';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  getUserProfileData,
  saveUserProfileData,
  setPrimaryOrgUnit,
  updateUserToken
} from 'store/features/Auth';
import { loadingStates } from 'models/enum';
import { ProgressSpinner, Typography } from 'components/atoms';
import { isNull } from 'lodash';
import { UserOrgUnit } from 'models/models';

interface IPermissionItem {
  orgUnit: UserOrgUnit;
  setPrimaryOrgUnit: (orgUnit: UserOrgUnit) => void;
}

const PermissionItem = ({ orgUnit, setPrimaryOrgUnit }: IPermissionItem) => {
  return (
    <div className="permission-item-container">
      <i
        className={
          orgUnit.isPrimary
            ? 'ri-star-fill permission-utem-icon selected'
            : 'ri-star-line permission-item-icon'
        }
        onClick={() => setPrimaryOrgUnit(orgUnit)}
      />
      <Typography variant="baseRegular">{orgUnit.orgUnitName}</Typography>
    </div>
  );
};

export const OrgUnitSelectionDropDown = () => {
  const dispatch = useAppDispatch();

  const { userProfileData, getUserProfileStatus } = useAppSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    dispatch(getUserProfileData());
  }, []);

  const redirectToHome = () => {
    window.location.href = '/';
  };

  const setPrimaryOrgUnitData = async (orgUnit: UserOrgUnit) => {
    dispatch(setPrimaryOrgUnit(orgUnit));
    const response = await dispatch(saveUserProfileData());
    if (response.meta.requestStatus === 'fulfilled') {
      const tokenResponse = await dispatch(updateUserToken());
      if (tokenResponse.meta.requestStatus === 'fulfilled') {
        redirectToHome();
      }
    }
  };

  return (
    <div className="orgunit-list-wrapper">
      {getUserProfileStatus === loadingStates.LOADING ? (
        <div className="orgunit-list-spinner-container">
          <ProgressSpinner size="default" color="gray" />
        </div>
      ) : (
        <div className="orgunit-list-container">
          {!isNull(userProfileData.userOrgUnits) &&
            userProfileData.userOrgUnits?.map((item) => (
              <PermissionItem
                key={item.orgUnitId}
                orgUnit={item}
                setPrimaryOrgUnit={setPrimaryOrgUnitData}
              />
            ))}
        </div>
      )}
    </div>
  );
};
