/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { loadingStates, roleTypes } from 'models/enum';
import {
  Avatar,
  Logo,
  NavMenuItem,
  UserProfileDropdown,
  Alert,
  NavItem,
  HelpAndSupport,
  OrgUnitSelectionDropDown,
  Button
} from 'components';
import { RootState, useAppSelector } from 'store';
import useWindowDimensions from 'hooks/useWindowDimensions';
import agent from 'services/service';
import './index.scss';
import { ISubMenu } from 'models/models';

interface IResponse {
  errors: any;
  code: number;
  data: any;
}

const navItems = [
  {
    id: uuidv4(),
    navItemName: 'Hjem',
    navItemPath: `/`,
    navItemIcon: 'ri-home-4-line',
    navItemIconSelect: 'ri-home-4-fill',
    access: []
  },
  {
    id: uuidv4(),
    navItemName: 'Undersøkelser',
    navItemPath: `${process.env.REACT_APP_SURVEY_BASE_URL}`,
    navItemIcon: 'ri-survey-line',
    navItemIconSelect: 'ri-survey-fill',
    access: [
      roleTypes.KF_ADMIN,
      roleTypes.ADMIN,
      roleTypes.REPORT_USER,
      roleTypes.ADMIN_EM_SURVEY,
      roleTypes.REPORT_USER_EM_SURVEY,
      roleTypes.FORM_BUILDER,
      roleTypes.RESPONDENT_REPORT_USER
    ],
    subMenuItems: [
      {
        id: uuidv4(),
        subNavItemName: 'Ny undersøkelse',
        subNavItemPath: `${process.env.REACT_APP_SURVEY_BASE_URL}templates/`,
        subNavItemIcon: '',
        access: true
      },
      {
        id: uuidv4(),
        subNavItemName: 'Mine undersøkelser',
        subNavItemPath: `${process.env.REACT_APP_SURVEY_BASE_URL}`,
        subNavItemIcon: '',
        access: true
      }
    ],
    isDisabled: true
  },
  {
    id: uuidv4(),
    navItemName: 'Organisasjonsstruktur',
    navItemPath: `/orgunits/`,
    navItemIcon: 'ri-organization-chart',
    navItemIconSelect: 'ri-organization-chart',
    access: [roleTypes.KF_ADMIN, roleTypes.ADMIN, roleTypes.ADMIN_EM_SURVEY]
  },
  {
    id: uuidv4(),
    navItemName: 'Tilgangsstyring',
    navItemPath: '/users/',
    navItemIcon: 'ri-team-line',
    navItemIconSelect: 'ri-team-fill',
    access: [roleTypes.KF_ADMIN, roleTypes.ADMIN, roleTypes.ADMIN_EM_SURVEY]
  },
  // Remove obsolete items from the navigation bar
  // {
  //   id: uuidv4(),
  //   navItemName: 'Rapportverktøy',
  //   navItemPath: `${process.env.REACT_APP_BK_BASE_URL}/showKFReportTools.action`,
  //   navItemIcon: 'ri-line-chart-line',
  //   navItemIconSelect: 'ri-line-chart-fill',
  //   access: []
  // },
  {
    id: uuidv4(),
    navItemName: 'Admin verktøy',
    navItemPath: `/admintools/`,
    navItemIcon: 'ri-list-check-2',
    navItemIconSelect: 'ri-list-check-2',
    access: [roleTypes.KF_ADMIN]
  }
  // {
  //   id: uuidv4(),
  //   navItemName: 'Spørreskjemabygger',
  //   navItemPath: `${process.env.REACT_APP_BK_BASE_URL}/admin/showMyWizards.action`,
  //   navItemIcon: 'ri-file-edit-line',
  //   navItemIconSelect: 'ri-file-edit-fill',
  //   access: [roleTypes.KF_ADMIN, roleTypes.FORM_BUILDER]
  // }
];

export const TopNavBar = () => {
  const seeMoreRef = useRef<any>(null);
  const dropDownRef = useRef<any>(null);
  const userToken = useAppSelector(({ auth }) => auth.token);
  const jwtDecodedToken: any = userToken && jwt_decode(userToken);
  const userDisplayName = useAppSelector(({ auth }) => auth.displayName);
  const [accessibleRoutes, setAccessibleRoutes] = useState<Array<any>>([]);
  const [mainNavItems, setMainNavItems] = useState<Array<any>>([]);
  const [spillOverNavItems, setSpillOverNavItems] = useState<Array<any>>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [loginAsUserId, setLoginAsUserId] = useState<number | null>(null);
  const [navItemData, setNavItemData] = useState<Array<any>>(navItems);
  const [showHelpAndSupport, setShowHelpAndSupport] = useState<boolean>(false);
  const [showOrgUnitDrpoDown, setShowOrgUnitDropDown] =
    useState<boolean>(false);

  const isLogInAsUserVal = useAppSelector(
    ({ auth: { isLogInAsUser } }) => isLogInAsUser
  );
  const authenticationSettings = useAppSelector((state: RootState) => {
    return state.auth.userAuthenticationSettings;
  });

  const { width } = useWindowDimensions();

  const userRoles = Array.isArray(jwtDecodedToken.role)
    ? jwtDecodedToken.role
    : [jwtDecodedToken.role];

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (seeMoreRef?.current && !seeMoreRef.current?.contains(event?.target)) {
        setShowMore(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [seeMoreRef]);

  const [showProfileDropDown, setShowProfileDropDown] =
    useState<boolean>(false);

  const getLoginasuserUserid = async () => {
    const response: IResponse = await agent.Authentication.loginasuserUserid();
    if (response && response.code === 200) {
      setLoginAsUserId(response.data.userId);
    } else {
      Alert({
        description: response.errors.errorMessage
          ? response.errors.errorMessage
          : `serverfeil !!`,
        type: 'error',
        close: true,
        icon: true
      });
      return null;
    }
  };

  const generateUrlForloginAsUser = (pageName: string, loginId: number) => {
    switch (pageName) {
      case 'Tilgangsstyring': {
        return '/users/';
      }
      case 'Organisasjonsstruktur': {
        return '/orgUnits/';
      }
      case 'Undersøkelser': {
        return '/surveys/';
      }
      case 'Hjem': {
        return '/';
      }
      default: {
        return `${process.env.REACT_APP_BK_BASE_URL}/admin/impersonateUser.action?userId=${loginId}`;
      }
    }
  };

  useEffect(() => {
    if (isLogInAsUserVal) {
      getLoginasuserUserid();
    }
  }, []);

  useEffect(() => {
    const loginAsUserNavItemData: Array<any> = [];
    if (loginAsUserId) {
      navItemData.map((item) => {
        loginAsUserNavItemData.push({
          ...item,
          navItemPath: generateUrlForloginAsUser(
            item.navItemName,
            loginAsUserId
          )
        });
        return setNavItemData([...loginAsUserNavItemData]);
      });
    }
  }, [loginAsUserId, setNavItemData]);

  const mapAccessibleRoutes = () => {
    const accessibleRoutes: Array<any> = [];
    navItemData.map((item: any) => {
      if (isEmpty(item.access)) {
        accessibleRoutes.push({
          id: item.id,
          navItemName: item.navItemName,
          navItemPath: item.navItemPath,
          navItemIcon: item.navItemIcon,
          navItemIconSelect: item.navItemIconSelect,
          selected: item.navItemName === 'Hjem',
          navSubMenu: item.subMenuItems,
          navIsDisabled: item.isDisabled
        });
      } else if (userRoles.some((role: any) => item.access.includes(role))) {
        if (
          !accessibleRoutes.some(
            (routeItem) => routeItem.navItemName === item.navItemName
          )
        ) {
          let updatedSubMenuItems = item.subMenuItems;
          if (
            item.navItemName === 'Undersøkelser' &&
            !authenticationSettings.isEnableAddSurveyTemplate
          ) {
            updatedSubMenuItems = item.subMenuItems.filter(
              (subItem: ISubMenu) =>
                subItem.subNavItemName !== 'Ny undersøkelse'
            );
          }
          accessibleRoutes.push({
            id: item.id,
            navItemName: item.navItemName,
            navItemPath: item.navItemPath,
            navItemIcon: item.navItemIcon,
            navItemIconSelect: item.navItemIconSelect,
            selected: item.navItemName === 'Hjem',
            navSubMenu: updatedSubMenuItems,
            navIsDisabled: item.isDisabled
          });
        }
      }
      setAccessibleRoutes(accessibleRoutes);
      return null;
    });
  };

  useEffect(() => {
    mapAccessibleRoutes();
  }, [userToken, authenticationSettings.status === loadingStates.SUCCESS]);

  useEffect(() => {
    if (!isEmpty(accessibleRoutes)) {
      if (accessibleRoutes.length > 4) {
        setMainNavItems(accessibleRoutes.slice(0, 4));
        setSpillOverNavItems(
          accessibleRoutes.slice(4, 4 + accessibleRoutes.length)
        );
      }
    }
  }, [accessibleRoutes]);

  useEffect(() => {
    if (width < 1250) {
      setMainNavItems(accessibleRoutes.slice(0, 3));
      setSpillOverNavItems(
        accessibleRoutes.slice(3, 3 + accessibleRoutes.length)
      );
    }
    if (width > 1250 && width < 1420) {
      setMainNavItems(accessibleRoutes.slice(0, 4));
      setSpillOverNavItems(
        accessibleRoutes.slice(4, 4 + accessibleRoutes.length)
      );
    }
    if (width > 1420 && width < 1695) {
      setMainNavItems(accessibleRoutes.slice(0, 5));
      setSpillOverNavItems(
        accessibleRoutes.slice(5, 5 + accessibleRoutes.length)
      );
    }
    if (width > 1695) {
      setMainNavItems(accessibleRoutes);
      setSpillOverNavItems([]);
    }
  }, [width, accessibleRoutes]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        dropDownRef?.current &&
        !dropDownRef.current?.contains(event?.target)
      ) {
        setShowOrgUnitDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <div className="header-content">
      <div className="logo">
        <Logo style={{ width: '182px' }} />
      </div>
      <div className="menu">
        {!isEmpty(mainNavItems) &&
          mainNavItems.map((menuItem) => {
            return (
              <div key={menuItem.id} className="menu-item">
                <NavMenuItem
                  link={menuItem.navItemPath}
                  title={menuItem.navItemName}
                  selected={menuItem.selected}
                  icon={
                    menuItem.selected
                      ? menuItem.navItemIconSelect
                      : menuItem.navItemIcon
                  }
                  subMenu={menuItem.navSubMenu}
                  isDisabled={menuItem.navIsDisabled}
                />
              </div>
            );
          })}
        {!isEmpty(spillOverNavItems) && (
          <div
            className="nav-menu-item menu-see-more"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowMore(!showMore)}
          >
            <span> Se mer </span>
            <i
              className="ri-arrow-down-s-line"
              onClick={() => setShowMore(!showMore)}
            />

            {showMore && (
              <div className="dropdown-wrapper--seeMoreNav" ref={seeMoreRef}>
                {spillOverNavItems.map((menuItem) => {
                  return (
                    <div key={menuItem.id}>
                      <NavMenuItem
                        link={menuItem.navItemPath}
                        title={menuItem.navItemName}
                        selected={menuItem.selected}
                        icon={
                          menuItem.selected
                            ? menuItem.navItemIconSelect
                            : menuItem.navItemIcon
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="profile-notification">
        <NavMenuItem
          link={process.env.REACT_APP_HELP ? process.env.REACT_APP_HELP : `#`}
          isNewTab
          title="Hjelp og støtte"
          icon="ri-question-line"
        />
        {/* <NavItem
          title="Hjelp og støtte"
          icon="ri-question-line"
          onClick={() => setShowHelpAndSupport(true)}
        /> */}
        {/* <div className="notification">
            <span className="notification-count">1</span>
            <i className="ri-notification-3-fill" />
          </div> */}
        <div className="orgunit-selection">
          <Button
            label=""
            icon="ri-star-fill"
            onClick={() => setShowOrgUnitDropDown(!showOrgUnitDrpoDown)}
            buttonClassNames="orgunit-selection-button"
          />
          {showOrgUnitDrpoDown && (
            <div
              className="dropdown-wrapper--orgunitSelection"
              ref={dropDownRef}
            >
              <OrgUnitSelectionDropDown />
            </div>
          )}
        </div>
        <div className="profile">
          <div
            className="user-profile-avatar"
            onClick={() => setShowProfileDropDown(!showProfileDropDown)}
          >
            <Avatar
              size="small"
              initial={`${
                userDisplayName !== null &&
                userDisplayName.match(/\b(\w)/g)?.join('')
              }`}
            />
          </div>
          {showProfileDropDown && (
            <div className="dropdown-wrapper--userProfile">
              <UserProfileDropdown
                setShowProfileDropDown={setShowProfileDropDown}
              />
            </div>
          )}
        </div>
      </div>

      <HelpAndSupport
        showHelpAndSupport={showHelpAndSupport}
        closeHelpAndSupport={setShowHelpAndSupport}
      />
    </div>
  );
};
